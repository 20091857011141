<template>
  <div>
    <vx-card>
      <div class="vx-row find-people">
       <!-- Intent leads table-->
        <div class="vx-col xl:w-full col-cus-12 mb-8 w-full ">
          <div class="card-title more-card-title">
              <h2 class="mb-0">Lead Distribution Rules</h2>
              <p class="mb-3">Use this section to create rules for lead distribution.  Set individual rules per user based on geographic location, industry, revenue and employee size.</p>

            <div class="flex btn-search w-full justify-between ">
              <vs-button
                title="Update"
                color="success"
                type="filled"
                icon-pack="feather"
                icon="icon-plus"
                class="mr-2 float-left "
                @click="companyDetailPopup = true"
                >{{ LabelConstant.buttonLabelAdd }}</vs-button
              >
              <vs-input
                icon-pack="feather"
                icon="icon-search"
                placeholder="Search"
                v-model="searchTerm"
                class="is-label-placeholder mt-0 width-300"
              />
            </div>
          </div>
          <div class="common-table pt-0">
            <!-- table -->
            <vs-table  v-model="selected"  :data="users" pagination  :max-items="itemsPerPage" >
              <template slot="thead">
                <vs-th></vs-th>
                <vs-th>Name</vs-th>
                <vs-th>States</vs-th>
                <vs-th>Zip Codes</vs-th>
                <vs-th>Indutries</vs-th>
                <vs-th>Revenue Size </vs-th>
                <vs-th>Employee Size</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  :data="tr"
                  :key="indextr"
                  v-for="(tr, indextr) in data"
                >
                 <vs-td width="90">
                        <div class="table-action">
                            <vs-button title="Edit"  color="primary" type="filled" icon-pack="feather" icon="icon-edit" class="mr-2 float-left"></vs-button>
                            <vs-button title="Delete"   color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" class="mr-0"></vs-button>
                        </div>
                </vs-td>
                  <vs-td :data="data[indextr].name">
                      {{ data[indextr].name }}
                  </vs-td>
                  <vs-td :data="data[indextr].location">
                        {{ data[indextr].location }}<br/>
                        {{ data[indextr].location }}
                  </vs-td>
                   <vs-td :data="data[indextr].zip">
                      {{ data[indextr].zip }}
                  </vs-td>
                   <vs-td :data="data[indextr].industry">
                      {{ data[indextr].industry }}<br/>
                       {{ data[indextr].industry }}
                  </vs-td>
                   <vs-td :data="data[indextr].revenue">
                      {{ data[indextr].revenue }}<br/>
                      {{ data[indextr].revenue }}
                  </vs-td>
                   <vs-td :data="data[indextr].employee">
                      {{ data[indextr].employee }}
                  </vs-td>
                  
                </vs-tr>
              </template>
            </vs-table>
           
          </div>
        </div>
      </div>
    </vx-card>
     <!--Add/edit  Campaign  modal -->

    <vs-popup
      class=""
      title="Add Lead Headling"
      :active.sync="companyDetailPopup">
         <template>
        <form data-vv-scope="senderForm">
          <div class="vx-row">
            <div class="vx-col  mb-6 w-full">
                <span class="vs-input--label w-full float-left">Country</span>
                 <v-select v-model="selected" placeholder="select" label="title" :options="options" />
            </div>
            <div class="vx-col  mb-6 w-full">
            <vs-checkbox v-model="checked" class="ml-0">Disable All Leads to this user</vs-checkbox>
           
            </div>
            <div class="vx-col  mb-6 w-full">
            <vs-checkbox v-model="checked" class="ml-0">Enable all leads for this user</vs-checkbox>
           
            </div>
            <div class="vx-col  mb-6 w-full">
                <span class="vs-input--label w-full float-left">States</span>
                <v-select
                      class="multiple-select type-multiselect"
                      
                      :options="options"
                      multiple
                    />
            </div>
             <div class="vx-col  mb-6 w-full">
                <span class="vs-input--label w-full float-left">Zip Codes</span>
                <vs-textarea
                  v-model="address1"
                  class="mb-0"
                  name="address1"
                 placeholder="Type here"
                />
            </div>
            <div class="vx-col  mb-6 w-full">
                <span class="vs-input--label w-full float-left">Indutries</span>
                <v-select
                      class="multiple-select type-multiselect"
                      
                      :options="options"
                      multiple
                    />
            </div>
             <div class="vx-col  mb-6 w-full">
                <span class="vs-input--label w-full float-left">Revenue Size</span>
                <v-select
                      class="multiple-select type-multiselect"
                      
                      :options="options"
                      multiple
                    />
            </div>
             <div class="vx-col  mb-6 w-full">
                <span class="vs-input--label w-full float-left">Employee count</span>
                <div class="vx-row align-center">
                <div class="vx-col  mb-6  md:w-1/3 mb-6 w-full">
                <vs-input
                  class="w-full"
                  label="From"
                  name="emailSubject"
                />
                </div>
                 <div class="vx-col  mb-6 md:w-1/3 mb-6 w-full">
                <vs-input
                  class="w-full"
                  label="To"
                  name="emailSubject"
                />
                 </div>
                <div class="vx-col  mb-6 md:w-1/3 mb-6 w-full">
                <p class="py-4">Employee </p>

                </div>
             </div>
            </div>
          </div>
        </form>
      </template>
      <div class="text-right mt-6 mb-8">
         <vs-button title="Update" color="primary" type="filled" class="mr-2 " >{{ LabelConstant.buttonLabelSave }}</vs-button>
        <vs-button color="grey"  @click="companyDetailPopup = false" type="border" class="ml-4 grey-btn mr-3" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>
    </vs-popup>
    </div>   
</template>

  <script>
import CollapseAccordion from "../../components/collapse/CollapseAccordion";
import InputTag from "vue-input-tag";
import { BMedia, BImg } from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    CollapseAccordion,
    InputTag,
    BMedia,
    BImg,
    "v-select": vSelect,
    Datepicker,
  },
  data: () => ({
    mainActiveTab: 0,
    companyDetailPopup: false,
    selected: "Leanne",
    options: ["Leanne", "Bret", "baz"],
    users: [
      {
        id: 1,
        name: "Leanne Ltd",
        location: "Bret",
        zip:"None",
        revenue: "$10M to 50M",
        industry: "Internet",
        employee: "10 to 100",
        latestupdate: "09/01/2020",

      },
      {
        id: 2,
        name: "Leanne Ltd",
        location: "Bret",
        zip:"None",
        revenue: "$10M to 50M",
        industry: "Internet",
        employee: "10 to 100",
        latestupdate: "09/01/2020",
      },
      {
        id: 3,
        name: "Leanne Ltd",
        location: "Bret",
        zip:"None",
        revenue: "$10M to 50M",
        industry: "Internet",
        employee: "10 to 100",
        latestupdate: "09/01/2020",
      },
      {
        id: 4,
        name: "Leanne Ltd",
        location: "Bret",
        zip:"None",
        revenue: "$10M to 50M",
        industry: "Internet",
        employee: "10 to 100",
        latestupdate: "09/01/2020",
      },
      {
        id: 5,
        name: "Leanne Ltd",
        location: "Bret",
        zip:"None",
        revenue: "$10M to 50M",
        industry: "Internet",
        employee: "10 to 100",
        latestupdate: "09/01/2020",
      },
      {
        id: 6,
        name: "Leanne Ltd",
        zip:"None",
        revenue: "$10M to 50M",
        industry: "Internet",
        employee: "10 to 100",
        latestupdate: "09/01/2020",
        location: "3284  Snyder Avenue",
      },
      {
        id: 7,
        name: "Leanne Ltd",
        zip:"None",
        revenue: "$10M to 50M",
        industry: "Internet",
        employee: "10 to 100",
        latestupdate: "09/01/2020",
        location: "3284  Snyder Avenue",
      },
      {
        id: 8,
        name: "Leanne Ltd",
        zip:"None",
        revenue: "$10M to 50M",
        industry: "Internet",
        employee: "10 to 100",
        latestupdate: "09/01/2020",
        location: "3284  Snyder Avenue",
      },
      {
        id: 9,
        name: "Leanne Ltd",
        zip:"None",
        revenue: "$10M to 50M",
        industry: "Internet",
        employee: "10 to 100",
        latestupdate: "09/01/2020",
        location: "3284  Snyder Avenue",
      },
      {
        id: 10,
        name: "Leanne Ltd",
        zip:"None",
        revenue: "$10M to 50M",
        industry: "Internet",
        employee: "10 to 100",
        latestupdate: "09/01/2020",
        location: "3284  Snyder Avenue",
      },
    ],
  }),

};
</script>